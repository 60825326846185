.activity {
  min-height: 100vh;
  background-color: #f4f4f4;
}

.activity-indicator {
  position: relative;
  top: 40vh;
  display: flex;
  justify-content: center;
}

#codeArea > img {
  width: 100%;
  height: 100%;
}
