.goods {
  display: flex;
}
.goods + .goods {
  margin-top: 10px;
}
.goods__image {
  flex-shrink: 0;
  width: 85px;
  height: 85px;
  margin-right: 10px;
  border-radius: 6px;
  background-color: #f6f6f6;
  pointer-events: none;
}
.goods__image--simple {
  width: 70px;
  height: 70px;
}
.goods__desc {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 40%;
  overflow: hidden;
}
.goods__name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}
.goods__spec {
  font-size: 12px;
  color: #969696;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.goods__misc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 1;
}
.goods__price {
  font-weight: 500;
}
.goods__price--red {
  color: #ff4f64;
}
.goods__profit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goods__profit + .goods__profit {
  margin-top: 4px;
}
.goods__profit--left {
  font-size: 12px;
  color: #969696;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.goods__profit--right {
  flex-shrink: 0;
  margin-left: 4px;
  font-size: 12px;
  color: #ff4f64;
}
