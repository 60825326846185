.selected-list-header {
  height: 60px;
  line-height: 60px;
  background: #f9fafb;
  font-size: 16px;
  color: #333;
  text-align: center;
}

.selected-list-thead {
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 16px;
  font-size: 12px;
  color: #666;
}

.selected-list-tbody {
  position: relative;
  min-height: 50px;
  max-height: 224px;
  padding: 0 16px;
  overflow-y: auto;
}

.selected-list-empty {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #666;
}

.selected-list-tr {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 8px;
  background: #f6f6f6;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.selected-list-spec {
  flex-grow: 1;
  line-height: 20px;
  padding: 0 12px;
  text-align: left;
  overflow: hidden;
}
.selected-list-spec > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected-list-num,
.selected-list-action {
  flex-shrink: 0;
}

.selected-list-num {
  width: 100px !important;
}

.selected-list-action {
  width: 40px;
  color: #666;
}

.selected-list-confirm {
  padding: 8px 16px;
}
