.safe-area {
  width: 100%;
}

.safe-area--top {
  padding-top: var(--safe-area-inset-top);
}

.safe-area--bottom {
  padding-bottom: var(--safe-area-inset-bottom);
}
