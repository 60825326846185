.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty__image {
  width: 60vw;
  height: 48vw;
  object-fit: contain;
}

.empty__text {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(var(--color-primary-rgb), 0.5);
  text-align: center;
}

.empty__contact {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  line-height: 1;
  margin-top: 20px;
  padding: 0 20px;
  border-radius: 15px;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  font-size: 14px;
  box-sizing: border-box;
  user-select: none;
}
