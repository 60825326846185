.price--red {
  color: #f33258;
}
.price--orange {
  color: #ed601a;
}

.price-tag {
  line-height: 1;
  margin-right: 4px;
  padding: 0 2px;
  border-radius: 2px;
  font-size: 13px;
}
.price--red .price-tag {
  background-color: #ffe9e9;
}
.price--orange .price-tag {
  background-color: #fff3de;
}

.price-text {
  font-size: 16px;
  font-weight: 600;
}
