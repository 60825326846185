.more-orders {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 16px;
  color: #666;
  font-size: 14px;
}

.more-orders__link {
  margin-left: 4px;
  color: var(--color-primary) !important;
}
