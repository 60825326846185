.order-search {
  padding: 16px 32px;
}

.order-search__tip {
  margin-bottom: 12px;
  font-size: 12px;
  color: #ff4f64;
}

.order-search .am-list-body::before,
.order-search .am-list-body::after {
  display: none !important;
}
.order-search .am-list-item.am-input-item {
  height: 50px;
  padding-left: 0;
}
.order-search .am-list-item .am-input-control input {
  font-size: 16px;
  color: #333;
}

.order-search__list {
  margin-bottom: 56px;
}
