#p-1 {
  padding-top: 145.46%;
  background: url('//cdnimg81.yijiahaohuo.com/jpd-h5/reg-1.png?_t=1641891458993')
    center / cover no-repeat;
}
#p-2 {
  padding-top: 101.73%;
  background: url('//cdnimg81.yijiahaohuo.com/jpd-h5/reg-2.png?_t=1641891458993')
    center / cover no-repeat;
}
#p-3 {
  position: relative;
  padding-top: 81.06%;
  background: url('//cdnimg81.yijiahaohuo.com/jpd-h5/reg-3.png?_t=1641891458993')
    center / cover no-repeat;
}
#p-4 {
  padding-top: 121.6%;
  background: url('//cdnimg81.yijiahaohuo.com/jpd-h5/reg-4.png?_t=1641891458993')
    center / cover no-repeat;
}
#p-5 {
  padding-top: 96.53%;
  background: url('//cdnimg81.yijiahaohuo.com/jpd-h5/reg-5.png?_t=1641891458993')
    center / cover no-repeat;
}
#p-6 {
  padding-top: 103.73%;
  background: url('//cdnimg81.yijiahaohuo.com/jpd-h5/reg-6.png?_t=1641891458993')
    center / cover no-repeat;
}
#p-7 {
  padding-top: 33.33%;
  background: url('//cdnimg81.yijiahaohuo.com/jpd-h5/reg-7.png?_t=1641891458993')
    center / cover no-repeat;
}

#video {
  position: absolute;
  top: 25%;
  left: 7%;
  width: 86%;
  background: #000;
}

.reg-page .getNext {
  width: 80%;
  display: block;
  height: 40px;
  text-align: center;
  color: white !important;
  font-size: 18px;
  list-style: none;
  border: 1px solid #fff;
  position: fixed;
  bottom: calc(20px + var(--safe-area-inset-bottom));
  left: 10%;
  background: #3eb69d;
  border-radius: 80px;
  line-height: 38px;
  font-family: SYH;
}
.reg-page__register {
  position: relative;
  min-height: 100vh;
  padding-bottom: calc(72px + var(--safe-area-inset-bottom));
}
.reg-page__register > img {
  width: 100%;
  height: auto;
}

.reg-grey-lines {
  padding: 10px 0;
  border-color: #eaeaea;
}
.reg-grey-lines input {
  font-size: 14px !important;
}
.getVerify {
  background: #3eb69d;
  align-self: center;
  height: 46px;
  color: #fff;
  width: 35%;
  border-radius: 10px;
}
.getVerify.getVerify--disabled {
  background-color: #adbdbc;
}
.reg-done-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #3eb69d;
  padding: 20px;
  align-items: center;
  color: #fff;
}
.reg-done-page img {
  width: 120px;
}
.reg-done-page h3 {
  margin-top: 20px;
  max-width: 250px;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.tel-tips {
  display: flex;
  align-items: center;
  line-height: 20px;
  margin: 0 15px;
  padding: 15px;
  background-color: #f2fffe;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
}
.tel-tips .tel-icon {
  flex-shrink: 0;
  margin-right: 8px;
}
.red-tips-font {
  padding: 0 35px 13px;
  color: #e83340;
  font-size: 12px;
}
.gold-color {
  color: #fbe5af;
  margin: 12px 0;
  max-width: 250px;
}
.reg-done-page .rules-tip {
  max-width: 280px;
  margin: 24px 0;
  font-size: 13px;
}
.reg-done-page .rules-tip p {
  margin-top: 12px;
}
.reg-page .am-list-item {
  width: 100%;
}
.reg-page .darkness {
  width: 18px;
  height: 18px;
  margin-top: 2px;
  /* background-color: lightgrey; */
  border-radius: 50%;
  border: 1px solid #3eb69d;
}
.reg-page .sub {
  width: calc(100% - 70px);
  display: block;
  height: 45px;
  margin: 12px 35px 16px;
  border-radius: 30px;
  text-align: center;
  color: white;
  list-style: none;
  font-size: 18px;
  border: none;
  background: #3eb69d;
}

.reg-done-page .longPress {
  text-align: center;
  color: #fff;
  margin-top: 12px;
  width: 100%;
}
.down-app {
  min-width: 160px;
  height: 40px;
  padding: 0 12px;
  background-color: #f4d68b;
  display: block;
  margin: auto auto;
  border-radius: 30px;
  color: #6c582a;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
}

.reg-row {
  overflow: hidden;
  margin: 0 35px 13px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0 12px;
}

.reg-row img {
  width: 22px;
  height: 22px;
  margin-left: 5px;
}
.block-f > div {
  width: 100%;
}

.block-f .am-list-line {
  justify-content: space-between;
}
.block-f .am-list-extra {
  font-size: 14px !important;
}

.return-oversea {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
  color: #666666;
}

.reg-page .ant-select-selection,
.reg-page .ant-select-focused .ant-select-selection,
.reg-page .ant-select-selection:focus,
.reg-page .ant-select-selection:active {
  border: none;
  box-shadow: none;
}

.reg-page .ant-select-selection {
  width: 80px;
}

.reg-page .am-list-item .am-list-line .am-list-extra {
  flex-basis: auto;
}
