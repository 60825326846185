.tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 768px;
  height: 50px;
  padding-bottom: var(--safe-area-inset-bottom);
  background-color: white;
  box-sizing: content-box;
}
