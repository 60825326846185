.order-search-bar {
  position: relative;
  padding: 8px 12px 0;
  background-color: #fff;
}

.order-search-bar-input .ant-input {
  border-radius: 16px;
}

.order-search-bar-mask {
  position: absolute;
  top: 8px;
  left: 12px;
  right: 12px;
  bottom: 0;
  display: block;
}
