.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}
.container--fullpage {
  height: 100vh;
}

.title {
  margin-top: 12px;
  font-size: 20px;
  font-weight: 500;
}

.title + .desc {
  margin-top: 4px;
}
.desc {
  margin-top: 12px;
  font-size: 14px;
  color: #999999;
  text-align: center;
}

.button {
  width: 160px;
  height: 44px;
  line-height: 42px;
  margin: 48px auto 0;
  border: 1px solid #e4e4e4 !important;
  border-radius: 22px;
}
.button::before {
  border: none !important;
}
