.goods-card-container {
  width: 50%;
  padding: 4px;
}

.goods-card {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: 256px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
}

.goods-image {
  flex-shrink: 0;
  display: block;
  width: 100%;
  height: 168px;
  object-fit: cover;
  background-color: #ececec;
}

.goods-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 12px;
}

.goods-name {
  position: relative;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.goods-price {
  color: #ff4f65;
  font-size: 18px;
}
