.container {
  font-size: 14px;
  color: #333;
}

.province {
  display: flex;
}

.province-icon {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-right: 18px;
}

.province-content {
  flex: 1;
}

.province-name {
  line-height: 22px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}

.cities {
  margin-bottom: 12px;
}

.areas {
  color: #999;
}

.line {
  border-top: 1px solid #eee;
  margin-bottom: 12px;
}
