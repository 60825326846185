.cart-activity-card {
  width: 92%;
  margin: 0 auto 16px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.cart-activity-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 12px;
  background: linear-gradient(180deg, #ffe9e9 0%, #ffffff 100%);
}
.cart-activity-card-header span:first-child {
  font-weight: 600;
}

/* TODO */
.cart-activity-card .cart-exchange-ico {
  display: inline-block;
  width: auto;
  height: 14px;
  margin-right: 4px;
  vertical-align: -0.125em;
}

.multicart-item-container {
  line-height: 1;
  margin: 0 auto;
  padding: 8px 12px;
  background-color: #fff;
  border-radius: 6px;
  font-size: 12px;
  overflow: hidden;
}
.multicart-item {
  display: flex;
  align-items: flex-start;
}
.multicart-item-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 110px;
  margin-right: 8px;
}
.multicart-item-image {
  width: 80px;
  height: 80px;
  margin-left: 8px;
  text-align: right;
}
.multicart-item-image-wrapper {
  position: relative;
  display: inline-block;
}
.multicart-item-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.multicart-item-image-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.multicart-item-right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 90px;
}
.multicart-item-name {
  line-height: 1.5;
  margin-bottom: 4px;
  color: #333;
}
.multicart-item-seckill-icon {
  padding: 2px;
  color: #fff;
  border-radius: 5px;
  background: #ff6d8c;
  font-size: 10px;
}
.multicart-item-spec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  line-height: 1.25;
  color: #969696;
}
.multicart-item-delete {
  flex-shrink: 0;
  margin-left: 8px;
  font-size: 16px;
}
.multicart-item-action {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 8px;
}
.multicart-item-price {
  color: #ff6d8c !important;
  font-size: 18px;
}
.multicart-item-action .am-stepper {
  height: 30px;
}

.multicart-item-tip {
  margin-top: 8px;
}
.multicart-item-tip--padding {
  padding-left: 30px;
}
