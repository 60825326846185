:root {
  --color-primary-rgb: 165, 185, 163;
  --color-primary: rgb(var(--color-primary-rgb));
}

body {
  margin: 0;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum', 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff !important;
  color: #333;
  overflow-x: hidden;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body button {
  border: none;
  background-color: transparent;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  outline: none;
}
body p,
body ul,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin-bottom: 0;
}
body a {
  text-decoration: none;
  color: #333;
}
body a:link,
body a:visited {
  color: #333;
  text-decoration: none;
}
body a:hover {
  color: #333;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
}

@media only screen and (min-width: 768px) {
  body {
    max-width: 768px !important;
    margin: 0 auto !important;
  }
}
body .am-list-item .am-input-label.am-input-label-5 {
  width: auto;
}

body .ml-12 {
  margin-left: 12px;
}
body .padding-12 {
  padding: 12px;
}
body .mr-12 {
  margin-right: 12px;
}

body .mt-12 {
  margin-top: 12px;
}

body .mb-12 {
  margin-bottom: 12px;
}

body .mb-24 {
  margin-bottom: 24px;
}
body .mt-24 {
  margin-top: 24px;
}

/**
 * 开发时控制台报错导致页面无法操作：Uncaught ReferenceError: process is not defined
 * https://github.com/facebook/create-react-app/issues/11771#issuecomment-1017125835
 */
body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

/* 安全区域样式 */
@supports (bottom: constant(safe-area-inset-bottom)) or
  (bottom: env(safe-area-inset-bottom)) {
  :root {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-top: env(safe-area-inset-top);
  }
}

.am-button-primary:hover {
  color: #fff;
}
