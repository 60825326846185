.customize {
  display: flex;
  padding: 8px 16px;
  background-color: #fef2f0;
}
.customize__icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.customize__text {
  font-size: 12px;
  color: #ff4f64;
  line-height: 16px;
}
