.back-top {
  position: fixed;
  right: 10px;
  bottom: 60px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.back-top img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
