.contact-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 10px;
  border: 1px solid var(--btn-border-color, #e2e2e2);
  border-radius: 18px;
  background-color: var(--btn-bg-color, rgba(0, 0, 0, 0));
  font-size: 12px;
  color: var(--btn-color, currentColor);
}

.contact-modal-button__icon {
  width: 15px;
  height: 15px;
}
