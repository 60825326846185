.tip-popup {
  --button-default-border-color: #999999;
  --button-default-color: #333333;
  --button-primary-border-color: var(--color-primary);
  --button-primary-background-color: var(--color-primary);
  --button-small-height: 30px;
  --button-small-font-size: 13px;
  --padding-xs: 12px;

  width: 283px;
}
.tip-popup :global(.am-modal-content) {
  padding: 0;
  text-align: left;
}
.tip-popup :global(.am-modal-body) {
  padding: 16px !important;
}
.tip-popup__title {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22.5px;
}
.tip-popup__goods {
  display: flex;
  height: 66px;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 10px;
  box-sizing: border-box;
}
.tip-popup__goods + .tip-popup__goods {
  margin-top: 8px;
}
.tip-popup__goods__image {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  margin-right: 8px;
  border-radius: 3px;
  pointer-events: none;
}
.tip-popup__goods__right {
  flex-grow: 1;
  overflow: hidden;
}
.tip-popup__goods__name {
  font-size: 12px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tip-popup__goods__spec {
  font-size: 12px;
  color: #666666;
}
.tip-popup__scroll {
  max-height: 214px;
  overflow-y: auto;
}
.tip-popup__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.tip-popup__action {
  width: 120px;
}

.tip-popup :global(.am-button) {
  height: 36px;
  line-height: 36px;
  padding: 0;
  border-radius: 999px;
  font-size: 15px;
  color: #333333;
  user-select: none;
}
.tip-popup :global(.am-button::before) {
  border-radius: 999px !important;
  border-color: #999999 !important;
}
.tip-popup :global(.am-button-primary) {
  background-color: var(--color-primary);
  color: #ffffff;
}
.tip-popup :global(.am-button-primary.am-button-active) {
  background-color: var(--color-primary);
}
.tip-popup :global(.am-button-primary::before) {
  border-color: var(--color-primary) !important;
}
