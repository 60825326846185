.special-bar-wrap {
  min-height: 100vh;
  background-color: #f4f4f4;
}
.special-bar-wrap .am-activity-indicator {
  /* iOS 改变层级，下同 */
  transform: translateZ(1px);
}
.special-bar-wrap .am-tabs {
  overflow: initial;
}
.special-bar-wrap .am-tabs-tab-bar-wrap {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 400;
  transform: translateZ(1px);
}
/* 禁止 iOS 下出现滚动条 */
.special-bar-wrap .am-tabs-default-bar {
  overflow: hidden;
}
.order-loading-wrap {
  position: fixed;
  left: 0;
  top: 200px;
  width: 100%;
  z-index: 120;
}
.order-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  background-color: #fff;
  margin: 10px 3%;
  border-radius: 6px;
  padding: 7px 12px;
  position: relative;
  overflow: hidden;
}
.copy-input {
  position: absolute;
  top: -40px;
}
.order-item-padding {
  padding: 5px 0;
}
.order-img-box {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
  position: relative;
}
.order-img-box .partable-post-icon {
  width: 53px;
  height: 47px;
  position: absolute;
  bottom: 0px;
  right: 30px;
  pointer-events: none;
}
.order-post-time {
  display: flex;
  justify-content: space-between;
}

.order-img-box .order-goods-image {
  width: 90px;
  height: 90px;
  text-align: right;
}
.order-img-box .order-goods-image img {
  object-fit: cover;
}
.order-img-box img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.order-img-box .order-goods-name {
  display: flex;
  width: 67%;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
}
.order-goods-name h3 {
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.change-add {
  position: relative;
}
/* .change-add>a{
    justify-content: flex-start
} */
/* .change-add::before{
    content: '更改>';
    position: absolute;
    right: 3px;
    width: 43px
} */
.order-goods-name div {
  justify-content: space-between;
}
.modify-arrow {
  min-width: 40px;
  text-align: right;
}
.order-item-bot {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: flex-end;
  justify-content: space-between;
}
.order-item-bot-left {
  padding-top: 13px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
.order-item-bot-left button {
  padding: 5px 10px;
  border-radius: 15px;
  background: transparent;
  margin: 0 2px;
}
.order-item-bot-left .btn-pay {
  border: 1px solid #ff4f64;
  color: #ff4f64;
}
.order-item-bot-left .btn-pay-sec {
  background-color: #ff4f64;
  color: #fff;
}
.order-item-bot-left .btn-cancel {
  border: 1px solid #5c5c5c;
  color: #5c5c5c;
}
.order-item-bot-left .del-or-multi {
  flex-shrink: 0;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.cli-btn {
  border: 1px solid #e7e7e7;
}
.order-bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.order-item-bottom-left {
  display: flex;
  justify-content: space-between;
}
.change-order-address {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
/* .order-item-bot p{
    align-self: flex-end
} */
.order-detail-infos {
  padding: 5px 0;
  background-color: #fff;
  margin-bottom: 10px;
}
.order-detail-infos li {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  border-bottom: 1px solid #edebeb;
  height: 30px;
  padding: 3px 20px;
  align-items: center;
}
.order-detail-infos li span {
  color: #999999;
  width: 36%;
}
.order-detail-infos li p {
  margin-left: 30px;
  color: #333333;
  width: 60%;
}
.order-detail-handleBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}
.order-detail-handleBtn button {
  padding: 0 5px;
  min-width: 80px;
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
}
.consult-btn {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.paydone-btn {
  background: #c83b4c;
  color: #fff;
  margin-right: 5px;
}
.upload-cert-btn {
  color: #c83b4c;
  background-color: #fff;
  border: 1px solid #c83b4c;
}
.loading-tip-example {
  display: flex;
  justify-content: center;
  padding-top: 150px;
}
.order-code-detail {
  display: flex;
}
.order-code-detail input {
  min-width: 50%;
}
.order-code-detail button {
  border: 1px solid #999;
  border-radius: 17px;
  width: 50px;
  text-align: center;
}
.paydone-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px 20px 20px;
}
.paydone-modal h2 {
  font-size: 18px;
  line-height: 40px;
  border-bottom: 1px solid #e7e7e7;
  font-weight: bold;
}
.paydone-modal h3 {
  color: #c83b4c;
  font-size: 22px;
  line-height: 55px;
  border-bottom: 1px solid #e7e7e7;
}
.paydone-modal li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0;
  font-size: 14px;
}
.paydone-modal li div {
  display: flex;
  width: 83%;
}
.paydone-modal li div input {
  width: 70%;
}
.paydone-modal li button {
  align-items: flex-end;
  width: 45px;
  border: 1px solid #969696;
  border-radius: 14px;
  height: 20px;
}
.paydone-modal p {
  padding: 10px 0 20px 0;
  color: #c83b4c;
  font-size: 12px;
  text-align: left;
}
.file-upload-input {
  display: none;
}
.steps-wrap {
  width: 320px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 12%;
}
.sector-line {
  content: '';
  height: 110%;
  min-height: 100px;
  width: 2px;
  background-color: #e7e7e7;
  position: absolute;
  left: 112px;
  top: -30px;
  margin-bottom: 30px;
}
.steps-wrap li {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  z-index: 5;
}
.steps-wrap li:first-child {
  margin-top: 0;
  z-index: 65;
}
.steps-wrap li span {
  width: 90px;
  color: #afafaf;
  font-size: 15px;
}
.steps-wrap li p {
  width: 56%;
  color: #333333;
  padding-left: 3px;
}
.steps-wrap li button {
  color: var(--color-primary);
}
.step-plaform {
  background-color: var(--color-primary);
}
.step-customer {
  background-color: #f8bc67;
}
.step-circle {
  position: relative;
  z-index: 65;
  border-radius: 50%;
  position: relative;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  margin-right: 3px;
}
.step-circle:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #e7e7e7;
  left: -4px;
  top: -4px;
}

.log-modal h3 {
  color: var(--color-primary);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  /* padding: 10px 0; */
}
.log-modal ul {
  margin-top: 10px;
}
.log-modal ul li {
  display: flex;
  justify-content: flex-start;
  padding: 5px 0;
  border-bottom: 1px solid #e7e7e7;
}
.log-modal ul li span {
  color: #afafaf;
  width: 45%;
  text-align: left;
}
.log-modal ul li p {
  color: #4a4a4a;
}
.confirm-handle {
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.confirm-handle div {
  padding: 5px 20px;
  display: flex;
}
.confirm-handle span {
  margin-top: -2px;
  margin-right: 3px;
}
.confirm-handle button {
  height: 50px;
  width: 100%;
  background-color: #c23142;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
}
.agree-protocal {
  display: block;
  width: 300px;
  height: 45px;
  line-height: 45px;
  background-color: #c23142;
  color: #fff;
  border-radius: 20px;
  margin: 0 auto;
}
.order-d-bottom-total {
  padding: 5px 10px;
  background-color: #fff;
}
.order-d-half-top {
  position: absolute;
  left: 0;
  bottom: 80px;
  right: 0;
  top: 45px;
  overflow-y: scroll;
  z-index: 322;
}
.order-d-half-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 322;
}
.service-goods {
  display: flex;
}
.service-goods img {
  width: 100px;
}
#root .am-steps-item-finish .am-steps-item-icon > .am-steps-icon {
  color: var(--color-primary);
}
#root .am-steps-item-finish .am-steps-item-tail:after {
  background-color: var(--color-primary);
}
#root .am-steps-item-process .am-steps-item-icon {
  border-color: var(--color-primary);
}
#root .am-steps-item-process .am-steps-item-icon {
  background: var(--color-primary);
}
.fill-logistic {
  padding: 10px 50;
  font-size: 16px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  margin-top: 10px;
  color: #000;
  padding-bottom: 15px;
}
.fill-logistic p {
  margin: 5px 0;
}

.cl-top {
  border-top: 1px solid #dddddd;
  background-color: #fff;
  font-size: 15px;
  display: flex;
  height: 50px;
  flex-wrap: nowrap;
  align-items: center;
}
.cl-top > div {
  width: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 50px;
  height: 48px;
}
.cl-top > .mid-line {
  height: 60%;
  width: 1px;
  background-color: #dddddd;
}
.cl-top .active {
  border-bottom: 2px solid var(--color-primary);
}
.cl-top .num {
  border-radius: 8px;
  height: 16px;
  line-height: 16px;
  padding: 0 4px;
  background-color: var(--color-primary);
  color: #fff;
  align-self: center;
  margin-left: 3px;
  font-size: 12px;
}
.comments-whole .comment-box {
  display: flex;
  padding: 10px;
  background-color: #fff;
  justify-content: space-between;
  border-radius: 10px;
  margin-top: 10px;
}
.comments-whole .comment-box img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 7px;
}
.comments-whole .comment-box > div {
  width: 65%;
  display: flex;
  height: 90px;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px;
}
.comments-whole .comment-box .cbtn {
  align-self: flex-end;
  border: 1px solid #969696;
  color: #333;
  border-radius: 14px;
  padding: 3px 8px;
}

.logs-btn-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  align-items: center;
  background: #fff;
  border-top: 1px solid #e2e2e2;
}
.logs-btn-fixed button {
  height: 35px;
  border: 1px solid #5c5c5c;
  color: #5c5c5c;
  border-radius: 20px;
  padding: 0 15px;
}

.edit-button {
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
}

/* 订单组件 */
.order-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  font-size: 12px;
}
.order-item-container--small {
  justify-content: flex-start;
  padding-left: 8px;
  padding-right: 8px;
}
.order-item-container--small .order-item-info {
  flex-grow: 1;
}
.order-item-image {
  display: flex;
  align-items: center;
  align-self: flex-start;
  text-align: right;
}
.order-item-image .order-item-checkbox {
  flex-shrink: 0;
  margin-right: 4px;
}
.order-item-image img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.order-item-info {
  display: flex;
  flex-direction: column;
  color: #969696;
  margin-left: 8px;
}
.order-item-name {
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.order-item-spec {
  margin: 4px 0;
}
.order-item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-item-price {
  color: red;
  font-size: 14px;
}

/* 子订单弹窗取消 */
.sub-order-modal {
  font-size: 14px;
  text-align: left;
}
.sub-order-title {
  margin-bottom: 8px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.sub-order-tip-title {
  margin-bottom: 8px;
  color: #333;
}
.sub-order-tip {
  margin-bottom: 16px;
}
.sub-order-items {
  background-color: #f6f6f6;
  max-height: 200px;
  margin: 8px -15px 0;
  overflow-y: scroll;
}
.sub-order-items--large {
  max-height: 240px;
}
.sub-order-action {
  margin-top: 16px;
  text-align: center;
}
.sub-order-btn {
  width: 100px;
  height: 32px;
  border: 1px solid var(--color-primary);
  border-radius: 16px;
  color: var(--color-primary);
}
.sub-order-btn + .sub-order-btn {
  margin-left: 8px;
}
.sub-order-btn:disabled {
  border-color: rgba(var(--color-primary-rgb), 0.4);
  color: rgba(var(--color-primary-rgb), 0.6);
}
