.contact__base {
  display: flex;
  align-items: center;
  margin: 4px 0 16px;
}
.contact__logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #eee;
}
.contact__name {
  font-size: 16px;
  font-weight: 500;
}

.contact__qr {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact__qrcode {
  width: 194px;
  height: 194px;
  object-fit: contain;
  margin-bottom: 12px;
  background-color: #eee;
}
.contact__qrcode__tip {
  font-size: 14px;
}

.contact__mobile {
  line-height: 1.5;
  margin: 8px 0 16px;
  text-align: left;
}
.contact__mobile__tip {
  margin-top: 20px;
}
.contact__mobile__number {
  color: #3488d6 !important;
}
.contact__mobile__copy {
  margin-left: 6px;
  padding: 1px 2px;
  border-radius: 2px;
  background-color: #a0a0a0;
  font-size: 11px;
  color: #fff;
}
.contact__mobile__input {
  position: fixed;
  right: -1px;
  bottom: -1px;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.contact__btn {
  width: 95%;
  margin: 8px auto 0;
  border: 1px solid var(--color-primary);
  background-color: #fff;
  font-size: 14px;
  color: var(--color-primary);
  text-overflow: unset;
}
