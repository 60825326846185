.swiper-card {
  flex-shrink: 0;
  width: 168px;
  line-height: 20px;
  margin-right: 8px;
  padding-bottom: 12px;
  background: #ffffff;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
}

.swiper-cover {
  display: block;
  width: 100%;
  height: 168px;
  margin-bottom: 8px;
  object-fit: cover;
}

.swiper-name {
  position: relative;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 16px;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.swiper-price {
  padding: 0 16px;
}
