.search-result {
  min-height: 100vh;
  padding: 10px 0;
  background-color: #f4f4f4;
}

.search-result .order-item:first-child {
  margin-top: 0;
}

.search-result__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.search-result__empty__text {
  margin: 8px 0 20px;
  color: rgba(var(--color-primary-rgb), 0.5);
}
