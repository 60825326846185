.order-msg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.4;
  padding: 12px;
  background: #fff5d0;
  border-radius: 10px;
  color: #ed601a;
}

.order-msg > * + * {
  margin-left: 8px;
}

.order-msg-main {
  flex: 1;
  line-break: strict;
}

.order-msg-title {
  font-size: 1.125em;
  font-weight: 500;
}
