.sku-container {
  padding: 20px 16px var(--safe-area-inset-bottom);
  text-align: left;
}

.sku-head {
  display: flex;
}

.sku-image-container {
  flex-shrink: 0;
  width: 110px;
  height: 110px;
  margin-right: 12px;
  border-radius: 5px;
  background-color: #d8d8d8;
  overflow: hidden;
}
.sku-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sku-price {
  color: #ff4f64;
  font-size: 18px;
}

.sku-storage {
  color: #999;
  font-size: 12px;
}

.sku-selected {
  margin-top: 8px;
  color: #333;
  font-size: 14px;
  word-break: break-word;
}

.sku-body {
  max-height: 300px;
  margin: 12px 0;
  overflow-y: scroll;
}

.sku-label {
  padding: 8px 0;
  color: #666;
  font-size: 14px;
}

.sku-specs {
  display: flex;
  flex-wrap: wrap;
}
.sku-spec {
  margin-right: 16px;
  margin-bottom: 8px;
}
.sku-spec--disabled {
  background-color: #f5f5f5;
  color: #cacaca;
}

.sku-num {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.sku-action {
  padding: 8px 0;
}
