.jpd-btn {
  background: var(--color-primary);
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  font-variant-numeric: tabular-nums;
}

.jpd-btn--full {
  width: 100%;
}

.jpd-btn--primary {
  background-color: var(--color-primary);
  color: #fff;
}
.jpd-btn--plain {
  background-color: #eee;
  color: #333;
}
.jpd-btn--plain.jpd-btn--disabled {
  background-color: #f5f5f5;
  color: #cacaca;
}

.jpd-btn--small {
  height: 32px;
  padding: 0 16px;
  border-radius: 16px;
  font-size: 14px;
}
.jpd-btn--large {
  height: 48px;
  padding: 0 32px;
  border-radius: 24px;
  font-size: 16px;
}

.jpd-btn--disabled {
  background-color: #d2cccc;
  cursor: not-allowed;
}
